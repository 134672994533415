@use "sass:math";

$un: math.div(1, 10.8);

@mixin border-radius( $radius ) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow( $shadows... ) {
  -webkit-box-shadow: $shadows;
  -moz-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin box-sizing( $box-model ) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin transform( $transforms ) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transform-origin( $origins ) {
  -moz-transform-origin: $origins;
  -o-transform-origin: $origins;
  -ms-transform-origin: $origins;
  -webkit-transform-origin: $origins;
  transform-origin: $origins;
}

@mixin transition( $transition... ) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transform-style( $style ) {
  -moz-transform-origin: $style;
  -o-transform-origin: $style;
  -ms-transform-origin: $style;
  -webkit-transform-origin: $style;
  transform-origin: $style;
}

@mixin clear() {

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin ul-reset() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin mdi {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
