@import 'fonts';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@mdi/font/css/materialdesignicons.min.css';
@import 'material';
@import 'modals';

html {
  height: 100%;

  body {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;

    app-root {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

@import 'themes/serf_dark';
