@font-face {
  font-family: 'knee';
  src:  url('../fonts/knee/knee.eot?674x0d');
  src:  url('../fonts/knee/knee.eot?674x0d#iefix') format('embedded-opentype'),
    url('../fonts/knee/knee.ttf?674x0d') format('truetype'),
    url('../fonts/knee/knee.woff?674x0d') format('woff'),
    url('../fonts/knee/knee.svg?674x0d#knee') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'knee' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-settings:before {
  content: "\e900";
}
.icon-help:before {
  content: "\e901";
}
.icon-infos-patient:before {
  content: "\e902";
}
.icon-photo:before {
  content: "\e903";
}
.icon-commentaire:before {
  content: "\e904";
}
.icon-cancel:before {
  content: "\e905";
}
.icon-quit:before {
  content: "\e906";
}
.icon-turn-minus:before {
  content: "\e907";
}
.icon-turn-plus:before {
  content: "\e908";
}
.icon-panel-close:before {
  content: "\e909";
}
.icon-panel-open:before {
  content: "\e90a";
}
.icon-alert:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90c";
}
.icon-regle:before {
  content: "\e90d";
}
.icon-four-direction:before {
  content: "\e90e";
}
.icon-two-direction:before {
  content: "\e90f";
}
.icon-femur:before {
  content: "\e910";
}
.icon-tibia:before {
  content: "\e911";
}
.icon-close:before {
  content: "\e912";
}
.icon-reset:before {
  content: "\e913";
}
.icon-save:before {
  content: "\e914";
}
.icon-valid:before {
  content: "\e915";
}
.icon-kneecap:before {
  content: "\e916";
}
