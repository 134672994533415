@import 'mixin';

.dialog-settings {

  ~ * {

    .mat-dialog-container {
      width: $un * 1440vh;
      @include border-radius( $un * 10vh );
      border: $un * 1vh solid;
      padding: 0;
    }
  }
}

.dialog-valid {

  ~ * {

    .mat-dialog-container {
      width: $un * 500vh;
      @include border-radius( $un * 10vh );
      border: $un * 1vh solid;
      padding: 0;
    }
  }
}
