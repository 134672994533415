.serf {
	color: #FFFFFF;
	background-color: #272727;

	&.dark {

		.loader-container {
			background-color: #000000;
		}

		#app_header {
			background-color: #1C1C1C;
			border-color: #353535;

			#app_nav {

				ul {

					li {

						a {
			        background-color: #000000;

							&:hover {
								background-color: #222222;

								.arrow {
									border-left-color: #222222;

									&::before {
										background-color: #222222;
									}
								}
							}

							.arrow {
								border-left-color: #000000;

								&::before {
									background-color: #000000;
								}
							}

							&.active {
								background-color: #EB7419;

								&:hover {
									background-color: #D25B00;

									.arrow {
										border-left-color: #D25B00;

										&::before {
											background-color: #D25B00;
										}
									}
								}

								.arrow {
									border-left-color: #EB7419;

									&::before {
										background-color: #EB7419;
									}
								}
							}

							&.logo {
								background-color: #FFFFFF;

								&:hover {
									background-color: #DDDDDD;

									.arrow {
										border-left-color: #DDDDDD;

										&::before {
											background-color: #DDDDDD;
										}
									}
								}

								.arrow {
									border-left-color: #FFFFFF;

									&::before {
										background-color: #FFFFFF;
									}
								}
							}
						}
					}
				}
			}

			#infos_patient {
				background-color: #000000;

				> div {
					border-color: #353535;
				}

				.ip-picto {
			    background-color: #EB7419;

					&::after {
				    border-left-color: #EB7419;
					}
				}

				.side {
					background-color: #EB7419;
				}

				.ip-title {
					color: #EB7419;
				}

				.ip-label,
				.ip-value {

					&.highlighted {
						color: #EB7419
					}
				}
			}
		}

		#app_toolbars {
			background-color: #171717;
			border-color: #353535;

			#app_toolbar_infos {

				.side {
					background-color: #EB7419;
				}

				.ti-inner {
					border-color: #353535;
					background-color: #000000;

					.if-value {

						&.highlighted {
							color: #EB7419;
						}
					}
				}
			}

			#app_toolbar_middle {

				.btn-group-label {
					border-color: #FFFFFF;
					background-color: #090909;
				}

				button {
					border-color: #353535;
					background-color: #000000;
					color: #FFFFFF;

					&:hover {
						border-color: #FFFFFF;
					}

					.has-comm {
						background-color: #EB7419;
						color: #FFFFFF;
					}

					&.active {
						border-color: #EB7419;
					}
				}

				#btn_valid {
					border-color: #EB7419;
					color: #EB7419;

					&:hover,
					&.valid {
						background-color: #EB7419;
						color: #FFFFFF;
					}
				}
			}

			#app_toolbar_right {

				#btn_settings,
			  #btn_help {
					border-color: #353535;

					i {
						color: #8A8A8A;
					}

					&:hover {

						i {
							color: #FFFFFF;
						}
					}
				}

				#btn_quit {
					border-color: #353535;

					i {
						color: #EB7419;
					}

					&:hover {

						i {
							color: #FFFFFF;
						}
					}
				}
			}
		}

		#comm {
			background-color: rgba( 0, 0, 0, .8 );
			border-color: #353535;

			.close {
				color: #EB7419;
				background-color: #000000;
				border-color: #353535;
			}

			textarea {
				color: #FFFFFF;
			}

			#valid_comm {
				background-color: #EB7419;
				color: #FFFFFF;
			}
		}

		.router-container {

			#preop_screen {
				background-color: #000000;
				border-color: #353535;
				background-image: url("../../img/background.png");

				#po_measure {
					border-color: #FFFFFF;

					&::before {
						background-color: #FFFFFF;
					}

					.pom-text {
						background-color: #EB7419;
					}
				}

				#po_warning {

					i {
						color: #EB7419;
					}
				}

				#po_toggle_tooltips {

				  #btn_pott {
						background-color: #EB7419;
						color: #FFFFFF;
					}
				}

				#po_view_left,
				#po_view_right {

					.pov-value-container {

						.tt-toggle {
							background-color: #272727;
							color: #FFFFFF;

							&.active {

								.tt-toggle-indicator {
									background-color: #EB7419;
								}
							}
						}

						.tt-content {
							border-color: #353535;
							background-color: #000000;

							&.tt-arrow-top {

								&::before {
									border-top-color: #353535;
								}

								&::after {
									border-top-color: #000000;
								}
							}

							&.tt-arrow-bottom {

								&::before {
									border-bottom-color: #353535;
								}

								&::after {
									border-bottom-color: #000000;
								}
							}
						}
					}
				}
			}

			#planif_screen {

				#femur_view_one,
				#femur_view_two,
				#femur_view_three,
				#tibia_view_one,
				#tibia_view_two,
				#tibia_view_three {

					.value {
						color: #FFFFFF;
					}

					.axe {
						color: #767676;
					}
				}

				#pl_panel_left {
					background-color: #000000;
					border-color: #353535;

					&::before {
						background-color: #353535;
					}

					#femur_switch_views {

						.toggle-all {
							background-color: #910170;

							.icon-quit {
								background-color: #5B0046;
							}
						}

						.toggle-ones {

							.toggle-one {
								border-color: #353535;

								&.active {
									border-color: #EB7419;
								}

								&:hover {
									border-color: #FFFFFF;
								}
							}
						}
					}

					#tibia_switch_views {

						.toggle-all {
							background-color: #00A6A5;

							.icon-quit {
								background-color: #005F5E;
							}
						}

						.toggle-ones {

							.toggle-one {
								border-color: #353535;

								&.active {
									border-color: #EB7419;
								}

								&:hover {
									border-color: #FFFFFF;
								}
							}
						}
					}

					#overview {

						.overview-label {
							background-color: #EB7419;
						}

						.commands-container {

					    .joystick-container {

					      svg {

					        path {

										&:first-child {

											&:hover {
												fill: #EB7419;
											}
										}
									}
								}
							}
						}

						button {
							border-color: #353535;
							background-color: #000000;
							color: #FFFFFF;

							&#btn_overview_plans {

								&.active {
									border-color: #EB7419;
								}

								&:hover {
									border-color: #FFFFFF;
								}
							}

							&#btn_overview_femur {

								.femur {
									fill: #transparent;
									stroke: #FFFFFF;
								}

								&.active {

									.femur {
										fill: #FFFFFF;
										stroke: transparent;
									}
								}
							}

							&#btn_overview_tibia {

								.tibia {
									fill: #transparent;
									stroke: #FFFFFF;
								}

								&.active {

									.tibia {
										fill: #FFFFFF;
										stroke: transparent;
									}
								}
							}

							&:hover {
								border-color: #FFFFFF;
							}
						}
					}
				}

				#pl_panel_right {
					background-color: #000000;
					border-color: #353535;
					background-image: url("../../img/background.png");

					.mask {
						background-color: #000000;
					}

					.femur-notice,
					.tibia-notice {
						color: #686868;
					}

					.diff-tibia-femur {
						color: #EB7419;
						border-color: #353535;
					}

					.diff-kneecap-one,
					.diff-kneecap-two {

						.diff {
							border-color: #EB7419;
							background-color: #EB7419;
							color: #FFFFFF;
						}
					}
				}
			}

			.select-femur {
				border-color: #353535;
				background-color: #000000;

				.title {
					background-color: #910170;
				}

				.select-row {

			    .separator {
						background-color: #353535;
					}

					mat-select {

					  &.mat-select {

					    .mat-select-arrow-wrapper {

					      &::before {
									color: #910170;
								}
							}
						}
					}
				}
			}

			.select-kneecap {
				border-color: #353535;
				background-color: #000000;

				.title {
					background-color: #759e00;
				}

				.select-row {

			    .separator {
						background-color: #353535;
					}

					mat-select {

					  &.mat-select {

					    .mat-select-arrow-wrapper {

					      &::before {
									color: #759e00;
								}
							}
						}
					}
				}
			}

			.select-tibia {
				border-color: #353535;
				background-color: #000000;

				.title {
					background-color: #00A6A5;
				}

				.select-row {

			    .separator {
						background-color: #353535;
					}

					mat-select {

					  &.mat-select {

					    .mat-select-arrow-wrapper {

					      &::before {
									color: #00A6A5;
								}
							}
						}
					}
				}
			}

			#views_choice {

				.btn-group-label {
					border-color: #FFFFFF;
					background-color: #090909;
				}

				.toggle-scanner {
					background-color: #000000;
					border-color: #353535;

					&::before {
						background-color: #FFFFFF;
					}

					&.active {

						&::before {
							background-color: #EB7419;
						}
					}

					&:hover {
						border-color: #FFFFFF;
					}
				}

				button {
					border-color: #353535;
					background-color: #000000;

					&:hover {
						border-color: #FFFFFF;
					}

			    &#btn_knee_cap {

			      &[data-state="2"] {

			        .knee-cap-top {
			          fill: #FFFFFF;
			        }

			        .knee-cap-bottom {
			          fill: #626262;
			        }
			      }

						&[data-state="1"] {
							border-color: #759e00;

							&:hover {
								border-color: #FFFFFF;
							}

			        .knee-cap-top {
			          fill: #FFFFFF;
			        }

			        .knee-cap-bottom {
			          fill: #FFFFFF;
			        }
			      }

						&[data-state="0"] {

			        .knee-cap-top {
			          fill: #FFFFFF;
			        }

			        .knee-cap-bottom {
			          fill: transparent;
								stroke: #FFFFFF;
			        }
			      }
			    }

					&#btn_cut {

			      &[data-state="2"] {

			        .cut-bg {
			          fill: #FFFFFF;
			        }

			        .cut-top {
			          fill: #000000;
			        }

			        .cut-bottom {
			          fill: #000000;
			        }
			      }

						&[data-state="1"] {

							.cut-bg {
			          fill: #313131;
			        }

			        .cut-top {
			          fill: #000000;
			        }

			        .cut-bottom {
			          fill: #000000;
			        }
			      }

						&[data-state="0"] {

							.cut-bg {
								fill: #000000;
								stroke: #FFFFFF;
							}

							.cut-top {
								fill: #000000;
								stroke: #FFFFFF;
							}

							.cut-bottom {
								fill: #000000;
								stroke: #FFFFFF;
							}
			      }
			    }

					&#btn_implant_femur {

			      &[data-state="2"] {

			        path {
			          fill: #FFFFFF;
			        }
			      }

						&[data-state="1"] {

							path {
			          fill: #313131;
			        }
			      }

						&[data-state="0"] {

							path {
			          fill: transparent;
								stroke: #FFFFFF;
			        }
			      }
			    }

					&#btn_implant_tibia {

			      &[data-state="2"] {

			        path {
			          fill: #FFFFFF;
			        }
			      }

						&[data-state="1"] {

							path {
			          fill: #313131;
			        }
			      }

						&[data-state="0"] {

							path {
			          fill: transparent;
								stroke: #FFFFFF;
			        }
			      }
			    }

					&#btn_transparency {

			      &[data-state="2"] {

			        path {
			          fill: #FFFFFF;
			        }
			      }

						&[data-state="1"] {

							path {
			          fill: #313131;
			        }
			      }

						&[data-state="0"] {

							path {
			          fill: transparent;
								stroke: #FFFFFF;
			        }
			      }
			    }

					&#btn_invert {

						rect {
							fill: #FFFFFF;
						}

						&[data-state="true"] {

							path {

								&:nth-child( 2 ) {
			          	fill: #313131;
								}

								&:nth-child( 3 ) {
			          	fill: #FFFFFF;
								}
			        }
			      }

						&[data-state="false"] {

							path {

								&:nth-child( 2 ) {
			          	fill: #FFFFFF;
								}

								&:nth-child( 3 ) {
			          	fill: #313131;
								}
			        }
			      }
					}
				}
			}

			.femur-adjustment {
				border-color: #353535;

				.separator {
					background-color: #353535;
				}

				.adjustment-item {

					.title {
						background-color: #1C1C1C;
						border-color: #353535;
					}

					.adjustment-row {

						.minus,
						.plus {
							color: #FFFFFF;
							background-color: #353535;
						}

						.minus {

							&::before {
								border-left-color: #353535 !important;
							}
						}

						.plus {

							&::before {
								border-right-color: #353535 !important;
							}
						}

						.value {
							background-color: #910170;
						}
					}
				}
			}

			.kneecap-adjustment {
				border-color: #353535;

				.separator {
					background-color: #353535;
				}

				.adjustment-item {

					.title {
						background-color: #1C1C1C;
						border-color: #353535;
					}

					.adjustment-row {

						.minus,
						.plus {
							color: #FFFFFF;
							background-color: #353535;
						}

						.minus {

							&::before {
								border-left-color: #353535 !important;
							}
						}

						.plus {

							&::before {
								border-right-color: #353535 !important;
							}
						}

						.value {
							background-color: #759e00;
						}
					}
				}
			}

			.tibia-adjustment {
				border-color: #353535;

				.separator {
					background-color: #353535;
				}

				.adjustment-item {

					.title {
						background-color: #1C1C1C;
						border-color: #353535;
					}

					.adjustment-row {

						.minus,
						.plus {
							color: #FFFFFF;
							background-color: #353535;
						}

						.minus {

							&::before {
								border-left-color: #353535 !important;
							}
						}

						.plus {

							&::before {
								border-right-color: #353535 !important;
							}
						}

						.value {
							background-color: #00A6A5;
						}
					}
				}
			}

			#panel_toggle {
				background-color: #000000;
				color: #FFFFFF;
			}
		}

		mat-select {

		  &.mat-select {
				background-color: #1C1C1C;

		    .mat-select-value {
		      color: #FFFFFF;
		    }

				&.dialog-select {
					background-color: #000000;

					.mat-select-arrow-wrapper {

						&::before {
							color: #EB7419;
						}
					}
				}
		  }
		}

		.cdk-overlay-pane {

		  .mat-select-panel {
				background-color: #1C1C1C;

		    .mat-option {
					background-color: #1C1C1C;

					&.mat-selected {
						background-color: #000000;
					}

		      .mat-option-text {
						color: #FFFFFF;
		      }
		    }
		  }
		}

		.mat-tooltip {

		  &.custom-tooltip {
				background-color: #000000;
				color: #FFFFFF;
				border-color: #353535;

				&::before {
					border-bottom-color: #353535;
				}

				&::after {
					border-bottom-color: #000000;
				}
			}
		}

		.dialog-settings,
		.dialog-valid {
			background-color: rgba( 28, 28, 28, .7 );

		  ~ * {

		    .mat-dialog-container {
					background-color: #000000;
					color: #FFFFFF;
					border-color: #282828;

					.dialog-title {
						border-color: #282828;
						background-color: #0F0F0F;

						.title {
							background-color: #000000;
							border-color: #282828;
						}

						.close {
							background-color: #EB7419;
						}
					}

					h2 {
						color: #EB7419;
					}

					.sub-part {

						&.embed {
							background-color: #282828;
						}
					}

					.dialog-register {
						background-color: #EB7419;
						color: #FFFFFF;
					}

					.dialog-yes {
						background-color: #EB7419;
						color: #FFFFFF;
					}

					.dialog-no {
						background-color: #888888;
						color: #000000;
					}

					input {

						+ .toggle-input {
							background-color: #000000;
							border-color: #353535;

							&::before {
								background-color: #FFFFFF;
							}
						}

						&:checked {

							+ .toggle-input  {

								&::before {
									background-color: #EB7419;
								}
							}
						}
					}

					.settings-row {

						.settings-col {
							border-color: #282828;
							background-color: #1A1A1A;

							.sc-title {
								color: #EB7419;
								border-color: #363636;
								background-color: #000000;

								&::before {
									border-top-color: #363636;
								}

								&::after {
									border-top-color: #000000;
								}

								i {
									color: #FFFFFF;
								}
							}

							.sc-inner-row {

								> div {

									&:last-child {
										color: #EB7419;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
