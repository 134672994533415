@import 'mixin';

mat-select {

  &.mat-select {
    width: $un * 187vh;
    height: $un * 25vh;
    @include border-radius( $un * 12vh );
    position: relative;

    &.dialog-select {
      height: $un * 35vh;
      @include border-radius( $un * 17vh );
      width: $un * 198vh;
    }

    &.pages-select {
      width: $un * 300vh;
      margin-left: $un * 20vh;
    }

    .mat-select-trigger {
      padding-left: $un * 15vh;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: auto;
      opacity: 1;
      @include transition( opacity .3s ease );

      &:hover {
        opacity: .6;
      }
    }

    .mat-select-value {
      font-size: $un * 14vh;
    }

    .mat-select-arrow-wrapper {

      > * {
        display: none;
      }

      &::before {
        content: "\F0140";
        @include mdi();
        margin-right: $un * 10vh;
        font-size: $un * 14vh;
      }
    }
  }
}

.disabled {

  .mat-select {
    opacity: .5 !important;
    pointer-events: none;
  }
}

.cdk-overlay-pane {
  @include transform( none !important );

  .mat-select-panel {
    @include box-shadow( none );
    margin-top: $un * 25vh;

    &.dialog-select {
      margin-top: $un * 35vh;
    }

    .mat-option {
      height: $un * 30vh;
      opacity: 1;
      @include transition( opacity .3s ease );

      &:hover {
        opacity: .6;
      }

      .mat-option-text {
        font-size: $un * 14vh;
        height: $un * 30vh;
        line-height: $un * 30vh;
      }
    }
  }
}

.mat-tooltip {

  &.custom-tooltip {
    margin: $un * 10vh;
    font-size: $un * 13vh;
    padding: $un * 8vh $un * 12vh;
    border: $un * 1vh solid;
    @include border-radius( $un * 3vh );
    overflow: visible;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      @include transform( translateX( -50% ) );
      border-left: $un * 12vh solid transparent;
      border-right: $un * 12vh solid transparent;
      border-bottom: $un * 12vh solid;
    }

    &::before {
      top: $un * -12vh;
    }

    &::after {
      top: $un * -11vh;
    }
  }
}
